/** @format */

import React, { useState, useEffect } from "react";
import AdminBookingTodayBooking from "../../components/adminComponents/adminBookingComponents/AdminBookingTodayBooking";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { navigateUserFunc } from "../../actions/generalActions";
import { useDispatch, useSelector } from "react-redux";
import AdminSearchByFilterComponentV2 from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponentV2";
import AdminOrderSearchTable from "../../components/adminComponents/adminDispatchingComponents/AdminOrderSearchTable";
// import * as XLSX from "xlsx";
import { tabUnassignedDispatching } from "../../actions/tabActions";
import { getAllStudents } from "../../actions/userActions";
import ExcelExport from "../../ExcelExport";
import moment from "moment";
import {
  getAllReservationV2,
  getQuoteReservationsByCompanyId,
  getTodayReservationsByCompanyId,
} from "../../actions/reservationAction";
import { notifySuccess } from "../../components/toast";
import AdminBookingQuote from "../../components/adminComponents/adminBookingComponents/AdminBookingQuote";

const AdminBookingQuotePage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const reservation = useSelector((state) => state?.reservation);
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      auth?.user?.permissionId?.booking?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
    dispatch(tabUnassignedDispatching());
  }, []);

  const [showFilter, setShowFilter] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const openFileUploadModal = () => {
    setShowUploadModal(true);
  };
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refresh = () => {
    // setIsRefreshing(true);
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      getQuoteReservationsByCompanyId(
        {
          companyId: auth.user.companyId.id,
          today: formattedChicagoDate,
          // isToday: true,
        },
        // ()=> {
        // setIsRefreshing(false);
        // }
        cb
      )
    );
    // setTimeout(() => {
    //   setIsRefreshing(false);
    // }, 2000);
  };
  const cb = (data) => {
    // setIsRefreshing(false);
    notifySuccess("Data Updated");
  };
  // const data = [
  //   { id: 1, name: "John Doe", age: 25 },
  //   { id: 2, name: "Jane Doe", age: 30 },
  //   // Add more objects as needed
  // ];

  // const handleExportToExcel = () => {
  //   // Convert the array of objects to a worksheet
  //   const ws = XLSX.utils.json_to_sheet(exportData);

  //   // Create a workbook
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "sheetName" || "Sheet 1");

  //   // Save the workbook to an Excel file
  //   XLSX.writeFile(wb, "exported-data.xlsx" || "data.xlsx");
  // };
  const [exportData, setExportData] = useState([]);

  // const data = reservation?.reservation?.quote?.map(item=>({
  const data = reservation?.reservation?.quote?.map((item) => {
    const dateString = item?.dateForReservation;
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item?.orderId,
      "Load Number": item?.loadNumber,
      "Reservation Date": formattedDate,
      "Passenger First Name": item?.customerId?.firstName,
      "Passenger Last Name": item?.customerId?.lastName,
      "Pick Up Address": item?.pickUpAddress?.address,
      "Drop Off Address": item?.dropOffAddress?.address,
      // "Pick Up Time": item?.pickUpTime,
      "Pick Up Time":
        (parseInt(item?.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.pickUpTime?.substring(0, 2)) > 12
          ? parseInt(item?.pickUpTime?.substring(0, 2)) - 12
          : parseInt(item?.pickUpTime?.substring(0, 2))) +
        ":" +
        item?.pickUpTime?.substring(3, 5) +
        (parseInt(item?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      // "Drop Off Time": item?.dropOffTime,
      "Drop Off Time":
        (parseInt(item?.dropOffTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.dropOffTime?.substring(0, 2)) > 12
          ? parseInt(item?.dropOffTime?.substring(0, 2)) - 12
          : parseInt(item?.dropOffTime?.substring(0, 2))) +
        ":" +
        item?.dropOffTime?.substring(3, 5) +
        (parseInt(item?.dropOffTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      "Driver First Name": item?.driverId?.firstName,
      "Driver Last Name": item?.driverId?.lastName,
      "Driver Amount": item?.fareAndDistance?.driverAmount
        ? parseFloat(item?.fareAndDistance?.driverAmount)
        : 0,
      Status: item?.status,
    };
  });

  // const data2 = reservation?.getReservationsByDateV2?.map(item=>({
  const data2 = reservation?.getReservationsByDateV2?.map((item) => {
    const dateString = item?.dateForReservation;
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item?.orderId,
      "Load Number": item?.loadNumber,
      "Reservation Date": formattedDate,
      "Passenger First Name": item?.customerId?.firstName,
      "Passenger Last Name": item?.customerId?.lastName,
      "Pick Up Address": item?.pickUpAddress?.address,
      "Drop Off Address": item?.dropOffAddress?.address,
      // "Pick Up Time": item?.pickUpTime,
      "Pick Up Time":
        (parseInt(item?.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.pickUpTime?.substring(0, 2)) > 12
          ? parseInt(item?.pickUpTime?.substring(0, 2)) - 12
          : parseInt(item?.pickUpTime?.substring(0, 2))) +
        ":" +
        item?.pickUpTime?.substring(3, 5) +
        (parseInt(item?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      // "Drop Off Time": item?.dropOffTime,
      "Drop Off Time":
        (parseInt(item?.dropOffTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.dropOffTime?.substring(0, 2)) > 12
          ? parseInt(item?.dropOffTime?.substring(0, 2)) - 12
          : parseInt(item?.dropOffTime?.substring(0, 2))) +
        ":" +
        item?.dropOffTime?.substring(3, 5) +
        (parseInt(item?.dropOffTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      "Driver First Name": item?.driverId?.firstName,
      "Driver Last Name": item?.driverId?.lastName,
      "Driver Amount": item?.fareAndDistance?.driverAmount
        ? parseFloat(item?.fareAndDistance?.driverAmount)
        : 0,
      Status: item?.status,
    };
  });

  // useEffect(() => {
  //   dispatch(getAllStudents({ companyId: auth.user.companyId.id }));
  // }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Online's Inquiries{" "}
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  {" "}
                  {/* <button
                    type="button"
                    className="btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Export Excel File"
                    onClick={handleExportToExcel}
                  >
                    <i className="fas fa-file-excel"></i>{" "}
                  </button> */}
                  {/* {reservation?.getReservationsByDateV2 ? (
                    <ExcelExport
                      excelData={data2}
                      // fileName={`Route Distance Report ${moment().format(
                      //   "MM/DD/YYYY"
                      // )}`}
                      fileName={`Booking Searched Report`}
                    />
                  ) : (
                    <ExcelExport
                      excelData={data}
                      fileName={`Quatation Report`}
                    />
                  )} */}
                  &nbsp;
                  {/* <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Refresh"
                    onClick={refresh}
                  >
                    <i className="fas fa-sync"></i>
                  </button> */}
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-original-title="Filters"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i className="fa fa-filter"></i>{" "}
                  </button>
                  &nbsp;
                  {/* <Link
                    type="button"
                    className="btn btn-pastt d-flex align-items-center"
                    to="/past-bookings"
                  >
                    Past Booking{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>{" "} */}
                  <Link
                    type="button"
                    className="btn btn-info d-flex align-items-center"
                    to="/online-bookings"
                  >
                    Online's Inquiries{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  {/* <Link
                    type="button"
                    className="btn btn-warning d-flex align-items-center"
                    to="/quotations"
                  >
                    Quotations{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link> */}
                  &nbsp;
                  <Link
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    to="/quote"
                  >
                    Create{" "}
                    <i
                      className="fa fa-plus-circle"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                </div>
              </div>
            </div>

            {showFilter && (
              <AdminSearchByFilterComponentV2 showFilter={showFilter} />
            )}

            {myState.viewDispatching === "dateSearch" ? (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminOrderSearchTable setExportData={setExportData} />{" "}
              </>
            ) : (
              <AdminBookingQuote
                setExportData={setExportData}
                // isRefreshing={isRefreshing}
              />
            )}
          </div>
        </section>
      </div>

      {/* <UploadFileModal /> */}
    </>
  );
};
export default AdminBookingQuotePage;
