import React, { useState } from "react";
// import logo2 from "../../assetsgeneral/images/header-logo.png"
import logo2 from "../../assets/images/CONNECT CARE TRANS-04.png";
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04 (1).png"
import { useDispatch, useSelector } from "react-redux";
import {
  tabContactUs,
  tabFaq,
  tabHome,
  tabServices,
  tabSignIn,
  tabWhyUs,
} from "../../actions/tabActions";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <div className="paraxdo-header-section">
        <div className="paraxdotop-header">
          <div className="container">
            <div className="row align-items-center ">
              {/* <div className="col-md-6">
                            <div className="tophead-text">
                            <span className="d-none d-md-block "><i className="fa fa-phone mx-3 ico-col"></i>(773) 340-4373</span>
                            </div>
                        </div> */}
              {/* <div className="col-md-6">
                            <div className="element">
                            <ul className="header-top-nav list-inline">
                                <li className="menu-item">
                                <span className="d-none d-lg-block"><i
                                    className="fa fa-envelope mx-3 ico-col"></i>contacts@connectcaretrans.com</span>
                                </li>
                            </ul>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          {/* <div className="row"> */}
          <div className="paraxdo-header-img">
            <nav className="navbar sticky-top navbar-expand-lg bg-dark">
              <div className="container">
                <a className="navbar-brand-new" href="/home">
                  {/* <img src="assets/images/header-logo.png" id="logsedf" alt="" /> */}
                  <img src={logo2} id="logsedf" className="navbar-logo-new" />
                </a>
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa fa-bars" aria-hidden="true"></i>
                            </button> */}
                {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {
                                myState.viewNavBar === "home" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new active-3" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new" onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>      
                                : 
                                    myState.viewNavBar === "whyus" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new active-3" onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "services" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new active-3" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "faq" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new active-3" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "contactus" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new " onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new active-3" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "signin" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new " onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    <div>Loading</div>
                                 }
                            </div> */}
              </div>
            </nav>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default NavBar;

{
  /* <div className="container"> */
}
{
  /* <div className="row"> */
}
// <div className="paraxdo-header-img">
// <nav className="navbar sticky-top navbar-expand-lg bg-dark">
// <div className="container">
// <NavLink to="/" className="navbar-brand">
// <img src={logo2} id="logsedf" alt="" className="navbar-logo-new"/>
// </NavLink>
// <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
{
  /* <i className="fa fa-bars" aria-hidden="true"></i> */
}
// </button>
{
  /* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {
                                myState.viewNavBar === "home" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new active-3" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new" onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>      
                                : 
                                    myState.viewNavBar === "whyus" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new active-3" onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "services" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new active-3" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "faq" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new active-3" onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "contactus" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new " onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new active-3" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    myState.viewNavBar === "signin" ?
                                <>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link-new" onClick={() => dispatch(tabHome())}>Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about-us" className="nav-link-new " onClick={() => dispatch(tabWhyUs())}>About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link-new" onClick={() => dispatch(tabServices())}>Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/booking" className="nav-link-new " onClick={() => dispatch(tabFaq())}>Booking</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-us" className="nav-link-new" onClick={() => dispatch(tabContactUs())}> Contact Us</NavLink>
                                        </li>
                                        <li className="nav-item sign-in">
                                            <NavLink to="/login" className="nav-link-new" onClick={() => dispatch(tabSignIn())}> Sign In</NavLink>
                                        </li>
                                    </ul>
                                </>
                                : 
                                    <div>Loading</div>
                                 }
                            </div>
                            <div className="nav-right-content">
                                <NavLink to="/login" className="info-bar-item-two">
                                    <div className="author-nav-content">
                                        <span> Sign In</span>
                                    </div>
                                </NavLink>
                            </div> */
}
{
  /* </div> */
}
{
  /* </nav> */
}
{
  /* </div> */
}
{
  /* </div> */
}
{
  /* </div> */
}
