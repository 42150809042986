/** @format */

import React, { useState } from "react";
import AdminBookingUploadExcelData from "../../../components/adminComponents/adminBookingComponents/AdminBookingUploadExcelData";
import axios from "axios";
// import { NavLink } from 'react-router-dom';
import XLSX from "sheetjs-style";
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { SERVERADDRESS } from "../../../constants";
import Dropzone from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import { notifyFailure, notifySuccess } from "../../toast";
import { useSelector } from "react-redux";
import moment from "moment";
import AdminBookingUploadFileModalTableRow from "./AdminBookingUploadFileModalTableRow";

function AdminBookingUploadFileModal({
  showUploadFileModal,
  toggleUploadFileModal,
}) {
  const directionsService = new window.google.maps.DirectionsService();
  const [loading, setLoading] = useState(null);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);
  const auth = useSelector((state) => state.auth);
  const fare = useSelector((state) => state.fare);
  const location = useSelector((state) => state.location);
  const students = useSelector((state) => state.students);
  const service = useSelector((state) => state.service);

  const handleSelectFile = (e) => {
    const acceptedFiles = e.target.files;
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const allowedMimeTypes = [
        "application/vnd.ms-excel", // MIME type for .xls files
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for .xlsx files
      ];
      if (allowedMimeTypes.includes(file.type)) {
        // The dropped file has a valid MIME type for Excel files
        setFile(file);
      } else {
        // Display an error message or handle invalid file types
        // console.error("Invalid file type. Please upload an Excel file.");
        notifyFailure("Invalid file type. Please upload a .xls file.");
      }
    }
  };
  const [showSubmit, setShowSubmit] = useState(false);
  const handleFileUpload = async () => {
    try {
      // setLoading(true);
      // setProgress(0);
      setData([]);
      const reader = new FileReader();
      reader.onload = async (event) => {
        // console.log("If =>");
        const binaryData = event.target.result;
        const workbook = XLSX.read(binaryData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let dataImport = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        dataImport.shift();
        dataImport = dataImport.filter((subArray) => subArray.length > 0);
        dataImport[0][15] = auth?.user?.companyId?.id;
        dataImport[0][16] = moment().format("YYYY-MM-DD");
        dataImport[0][17] = auth?.user?.id;
        // console.log("dataImport=>", dataImport);
        setTempData(dataImport);
        // for (let res of dataImport) {
        //   let oldTempData = [];
        //   oldTempData = tempData;
        //   oldTempData.push(res);
        //   setTempData(oldTempData);
        //   // calculateFare(res);
        // }
        // console.log("tempData=>", tempData);
        setShowSubmit(true);
        // if (dataImport.length <= 10) {
        //   const { data } = await axios.post(
        //     // `${SERVERADDRESS}/v1/reservation/importReservations`,
        //     // `${SERVERADDRESS}/v2/reservation/importReservations`,
        //     `${SERVERADDRESS}/v3/reservation/importReservations`,
        //     { dataImport }
        //   );
        //   setLoading(false);
        //   setProgress(25);
        //   setData(data);
        //   // console.log("If =>");
        //   setFile(null);
        //   notifySuccess("File Uploaded Successfully!");
        //   toggleUploadFileModal();
        // } else {
        //   let temp = [];
        //   const result = new Array(Math.ceil(dataImport.length / 10))
        //     .fill()
        //     .map((_) => dataImport.splice(0, 10));
        //   for (let i = 0; i < result.length; i++) {
        //     let imp = result[i];
        //     imp[0][15] = auth?.user?.companyId?.id;
        //     imp[0][16] = moment().format("YYYY-MM-DD");
        //     imp[0][17] = auth?.user?.id;
        //     const { data } = await axios.post(
        //       // `${SERVERADDRESS}/v1/reservation/importReservations`,
        //       // `${SERVERADDRESS}/v2/reservation/importReservations`,
        //       `${SERVERADDRESS}/v3/reservation/importReservations`,
        //       { dataImport: imp }
        //     );
        //     temp.push(...data);
        //     setProgress(parseInt(((i + 1) / result.length) * 100));
        //   }
        //   setLoading(false);
        //   setData(temp);
        //   // console.log("else =>");
        //   setFile(null);
        //   notifySuccess("File Uploaded Successfully!");
        //   toggleUploadFileModal();
        // }
      };
      reader.readAsBinaryString(file);
      // console.log("Handle File Upload =>");
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setProgress(20);
    if (tempData.length <= 1) {
      let newArr = [];
      newArr = tempData;
      newArr[0][15] = auth?.user?.companyId?.id;
      newArr[0][16] = moment().format("YYYY-MM-DD");
      newArr[0][17] = auth?.user?.id;
      const { data } = await axios.post(
        // `${SERVERADDRESS}/v1/reservation/importReservations`,
        // `${SERVERADDRESS}/v2/reservation/importReservations`,
        `${SERVERADDRESS}/v3/reservation/importReservations`,
        { dataImport: tempData }
      );
      setLoading(false);
      setProgress(25);
      setData(data);
      // console.log("If =>");
      setFile(null);
      notifySuccess("File Uploaded Successfully!");
      // toggleUploadFileModal();
      setShowSubmit(null);
    } else {
      let temp = [];
      const result = new Array(Math.ceil(tempData.length / 1))
        .fill()
        .map((_) => tempData.slice(0, 1));
      // .map((_) => tempData.splice(0, 1));
      for (let i = 0; i < result.length; i++) {
        let imp = result[i];
        imp[0][15] = auth?.user?.companyId?.id;
        imp[0][16] = moment().format("YYYY-MM-DD");
        imp[0][17] = auth?.user?.id;
        const { data } = await axios.post(
          // `${SERVERADDRESS}/v1/reservation/importReservations`,
          // `${SERVERADDRESS}/v2/reservation/importReservations`,
          `${SERVERADDRESS}/v3/reservation/importReservations`,
          { dataImport: imp }
        );
        temp.push(...data);
        setProgress(parseInt(((i + 1) / result.length) * 100));
      }
      setLoading(false);
      setData(temp);
      // console.log("else =>");
      setFile(null);
      notifySuccess("File Uploaded Successfully!");
      // toggleUploadFileModal();
      setShowSubmit(null);
    }
  };
  const handleFileUploadOpt = async () => {
    setLoading(true);
    const chunkSize = 2;
    const totalChunks = Math.ceil(tempData.length / chunkSize);
    let temp = [];

    for (let i = 0; i < totalChunks; i++) {
      if (totalChunks === 1) {
        setProgress(25);
      }
      let chunk = tempData.slice(i * chunkSize, (i + 1) * chunkSize);

      // Add additional info to the first row of each chunk
      chunk[0][15] = auth?.user?.companyId?.id;
      chunk[0][16] = moment().format("YYYY-MM-DD");
      chunk[0][17] = auth?.user?.id;
      try {
        const { data } = await axios.post(
          `${SERVERADDRESS}/v3/reservation/importReservations`,
          {
            dataImport: chunk,
          }
        );

        temp.push(...data);
        setProgress(Math.round(((i + 1) / totalChunks) * 100)); // Update progress
      } catch (error) {
        console.error("Upload failed for chunk", i + 1, error);
      }
    }

    setData(temp);
    setLoading(false);
    setFile(null);
    setShowSubmit(null);
    notifySuccess("File Uploaded Successfully!");
  };
  const handleClearFile = () => {
    setFile(null);
    setData([]);
  };
  const [tempData, setTempData] = useState([]);

  // const calculateFare = async (res) => {
  //   const query = {
  //     firstName: res[2],
  //     lastName: res[3],
  //   };
  //   // Conditionally add the phone field to the query if res[4] exists
  //   if (res[4]) {
  //     const incoming = res[4].toString();
  //     const numbers = incoming.match(/\d/g);
  //     const formatted = `(${numbers.slice(0, 3).join("")})${numbers
  //       .slice(3, 6)
  //       .join("")}-${numbers.slice(6, 10).join("")}`;
  //     query.phone = formatted;
  //   }
  //   const strType = res[5];
  //   // const regex = /\bwheelchair\b/i;
  //   const regex = /\bwheelchair\b|wheelchair/i;
  //   let newType;
  //   if (regex.test(strType)) {
  //     newType = "Wheelchair";
  //   } else if (strType.toLowerCase() === "wav") {
  //     newType = "Wheelchair";
  //   } else {
  //     newType = "Ambulatory";
  //   }
  //   let customer = students.list.find(
  //     (item) =>
  //       item.firstName === query.firstName &&
  //       item.lastName === query.lastName &&
  //       query.phone &&
  //       item?.phone === query.phone
  //   );
  //   let type = service.service.find((x) => x.name === newType);
  //   let fullNameExists = type.serviceName.some(
  //     (service) => service.fullName === res[5]
  //   );
  //   if (!fullNameExists) {
  //     // If fullName doesn't exist, push the new value
  //     type.serviceName.push({ fullName: res[5] });
  //     const { data } = await axios.put(
  //       `${SERVERADDRESS}/v1/service/updateService`,
  //       { _id: type.id, fullName: res[5] }
  //     );
  //     type = data;
  //     fullNameExists = data.serviceName.some(
  //       (service) => service.fullName === res[5]
  //     );
  //     // await type.save();
  //   }
  //   if (!customer) {
  //     const createBody = {
  //       firstName: res[2],
  //       lastName: res[3],
  //       companyId: auth.user.companyId.id,
  //       isRouteUser: false,
  //     };
  //     if (res[4]) {
  //       const incoming = res[4].toString();
  //       const numbers = incoming.match(/\d/g);
  //       const formatted = `(${numbers.slice(0, 3).join("")})${numbers
  //         .slice(3, 6)
  //         .join("")}-${numbers.slice(6, 10).join("")}`;
  //       createBody.phone = formatted;
  //     }
  //     const { data } = await axios.post(
  //       `${SERVERADDRESS}/v1/passenger/createPassenger`,
  //       createBody
  //     );
  //     customer = data;
  //   }
  //   let obj = {};
  //   let oldDateTime;
  //   let newDateTime;
  //   let newDate;
  //   let totalMileage = 0;
  //   // yahan se new logic
  //   let eta = { hour: [], mins: [] };
  //   let distanceFromBaseLocation = 0;
  //   let distanceFromPickToDropOff = 0;
  //   await directionsService
  //     .route({
  //       origin: {
  //         query: location.baseLocation.address,
  //       },
  //       destination: {
  //         query: res[7],
  //       },
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //       provideRouteAlternatives: true,
  //     })
  //     .then((response) => {
  //       console.log("response base", response);
  //       let temp = Infinity;
  //       // let value
  //       response.routes.forEach((e) => {
  //         let value = parseFloat(
  //           e.legs[0].distance.text.replace(/,/g, "").split(" ")[0]
  //         );
  //         let unit = e.legs[0].distance.text.includes("ft") ? "ft" : "mi";
  //         if (unit === "ft") {
  //           value /= 5280; // 1 mile is equal to 5280 feet
  //         }
  //         if (temp > value) {
  //           temp = value;
  //         }
  //       });
  //       distanceFromBaseLocation = parseFloat(temp);
  //       obj.distanceFromBaseLocation = temp;
  //     })
  //     .catch((e) => {
  //       notifyFailure(e.message);
  //       console.log("Directions request failed due to ", e.message);
  //       // window.alert("Directions request failed due to ", e)
  //     });
  //   let oldDate = new Date(
  //     Math.round((res[1] + res[6] - 25569) * 86400 * 1000)
  //   );
  //   let od = new Date(new Date(oldDate).getTime()).toUTCString();
  //   oldDateTime = od.toString().split(" ")[4];
  //   let pUpLat;
  //   let pUpLng;
  //   let dOffLat;
  //   let dOffLng;
  //   await directionsService
  //     .route({
  //       origin: {
  //         query: res[7],
  //       },
  //       destination: {
  //         query: res[8],
  //       },
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     })
  //     .then((response) => {
  //       console.log("response2", response);
  //       let value = parseFloat(
  //         response.routes[0].legs[0].distance.text
  //           .replace(/,/g, "")
  //           .split(" ")[0]
  //       );
  //       let unit = response.routes[0].legs[0].distance.text.includes("ft")
  //         ? "ft"
  //         : "mi";
  //       if (unit === "ft") {
  //         value /= 5280; // 1 mile is equal to 5280 feet
  //       }
  //       distanceFromPickToDropOff += parseFloat(value);
  //       pUpLat = response.routes[0].legs[0].start_location.lat;
  //       pUpLng = response.routes[0].legs[0].start_location.lng;
  //       dOffLat = response.routes[0].legs[0].end_location.lat;
  //       dOffLng = response.routes[0].legs[0].end_location.lng;
  //       if (response.routes[0].legs[0].duration.text.length > 7) {
  //         const timeString = response.routes[0].legs[0].duration.text;
  //         const hourRegex = /(\d+)\s*hour/;
  //         const minuteRegex = /(\d+)\s*min/;

  //         const hourMatch = hourRegex.exec(timeString);
  //         const minuteMatch = minuteRegex.exec(timeString);

  //         const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
  //         const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
  //         eta.hour.push(hours);
  //         eta.mins.push(minutes);
  //         let aaa = (hours * 60 + minutes) * 60 * 1000;
  //         // console.log(aaa, oldDate, "eta aarha hai");
  //         newDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
  //         newDateTime = oldDate.toString().split(" ")[4];
  //       } else {
  //         eta.mins.push(
  //           parseInt(response.routes[0].legs[0].duration.text.split(" mins")[0])
  //         );
  //         // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();
  //         let aaa =
  //           parseInt(
  //             response.routes[0].legs[0].duration.text.split(" mins")[0]
  //           ) *
  //           60 *
  //           1000;
  //         newDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
  //         newDateTime = oldDate.toString().split(" ")[4];
  //       }
  //       console.log("etaeta,", eta);
  //     })
  //     .catch((e) => {
  //       notifyFailure(e.message);
  //       console.log("Directions request failed due to ", e);
  //       // window.alert("Directions request failed due to ", e)
  //     });
  //   obj.apiDistanceFromBaseLocation = distanceFromBaseLocation;
  //   distanceFromPickToDropOff = parseFloat(
  //     distanceFromPickToDropOff.toFixed(2)
  //   );
  //   obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
  //   if (res[9]) {
  //     obj.usePrecalculatedMiles = true;
  //     distanceFromPickToDropOff = parseFloat(parseFloat(res[9]).toFixed(2));
  //     obj.precalculatedMiles = distanceFromPickToDropOff;
  //   } else {
  //     obj.usePrecalculatedMiles = false;
  //     obj.precalculatedMiles = distanceFromPickToDropOff;
  //   }
  //   obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
  //   totalMileage = parseFloat(
  //     (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
  //   );
  //   obj.totalMileage = totalMileage;
  //   let fares = fare.fare;
  //   let baseFare;
  //   let deadheadFare = 0;
  //   let rideFare;
  //   let companyFare = [];
  //   let serviceFare = [];
  //   let offHourFee = 0;
  //   let amount = 0;
  //   if (!customer.externalCompanyId) {
  //     fares = fare.fare[0].details;
  //     fares.forEach((e) => {
  //       if (e.serviceId.id.toString() == type.id.toString()) {
  //         serviceFare.push(e);
  //       }
  //       // console.log(
  //       //   e.serviceId._id.toString() == type._id.toString(),
  //       //   e.serviceId._id.toString(),
  //       //   type._id.toString(),
  //       //   "serviceFare3"
  //       // );
  //     });
  //     console.log(serviceFare, "serviceFare");
  //   } else {
  //     fares.forEach((e) => {
  //       if (e.business.id == customer.externalCompanyId) {
  //         companyFare.push(...e.details);
  //       }
  //     });
  //     companyFare.forEach((e) => {
  //       if (e.serviceId.id.toString() == type.id.toString()) {
  //         serviceFare.push(e);
  //       }
  //     });
  //     console.log(companyFare, serviceFare, "serviceFare2");
  //   }
  //   serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
  //   let _fare;
  //   for (let i = 0; i < serviceFare.length; i++) {
  //     if (totalMileage <= serviceFare[i].totalMileageAllows) {
  //       _fare = serviceFare[i];
  //       // console.log(_fare)
  //       break;
  //     }
  //   }
  //   if (!_fare) {
  //     _fare = serviceFare[serviceFare.length - 1];
  //   }
  //   obj.fare = _fare;
  //   console.log(fares, fare, serviceFare, _fare);
  //   if (distanceFromBaseLocation <= _fare.deadHeadThreshold) {
  //     baseFare = _fare.flatBaseFare;
  //     obj.baseFare = baseFare;
  //     obj.baseFareStructure = {
  //       values: { flatBaseFare: _fare.flatBaseFare },
  //       valuesFormula: "flatBaseFare",
  //     };
  //     obj.deadheadFare = 0;
  //     obj.deadheadFareStructure = {
  //       values: {
  //         baseFarePerMile: 0,
  //         distanceFromBaseLocation: distanceFromBaseLocation,
  //       },
  //       valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
  //     };
  //   } else {
  //     // yahan se new logic
  //     if (_fare.deadHeadThreshold) {
  //       // baseFare = _fare.flatBaseFare + _fare.baseFarePerMile * distanceFromBaseLocation;
  //       // obj.baseFare = baseFare;
  //       // obj.baseFareStructure = {
  //       //   values: {
  //       //     flatBaseFare: _fare.flatBaseFare,
  //       //     baseFarePerMile: _fare.baseFarePerMile,
  //       //     distanceFromBaseLocation: distanceFromBaseLocation,
  //       //   },
  //       //   valuesFormula: 'flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)',
  //       // };
  //       baseFare = _fare.flatBaseFare;
  //       obj.baseFare = baseFare;
  //       obj.baseFareStructure = {
  //         values: {
  //           flatBaseFare: _fare.flatBaseFare,
  //         },
  //         valuesFormula: "flatBaseFare",
  //       };
  //       deadheadFare = _fare.baseFarePerMile * distanceFromBaseLocation;
  //       obj.deadheadFare = _fare.baseFarePerMile * distanceFromBaseLocation;
  //       obj.deadheadFareStructure = {
  //         values: {
  //           baseFarePerMile: _fare.baseFarePerMile,
  //           distanceFromBaseLocation: distanceFromBaseLocation,
  //         },
  //         valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
  //       };
  //     } else {
  //       baseFare = _fare.flatBaseFare;
  //       obj.baseFare = baseFare;
  //       obj.baseFareStructure = {
  //         values: { flatBaseFare: _fare.flatBaseFare },
  //         valuesFormula: "flatBaseFare",
  //       };
  //       obj.deadheadFare = 0;
  //       obj.deadheadFareStructure = {
  //         values: {
  //           baseFarePerMile: 0,
  //           distanceFromBaseLocation: distanceFromBaseLocation,
  //         },
  //         valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
  //       };
  //     }
  //     // yahan takh new logic
  //   }
  //   if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
  //     // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
  //     rideFare = distanceFromPickToDropOff * _fare.farePerMile;
  //     obj.rideFare = rideFare;
  //     obj.rideFareStructure = {
  //       values: {
  //         distanceFromPickToDropOff: distanceFromPickToDropOff,
  //         farePerMile: _fare.farePerMile,
  //       },
  //       valuesFormula: "distanceFromPickToDropOff * farePerMile",
  //     };
  //   } else {
  //     // yahan se new logic
  //     if (_fare.totalMileageAllows) {
  //       rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
  //       obj.rideFare = rideFare;
  //       obj.rideFareStructure = {
  //         values: {
  //           distanceFromPickToDropOff: distanceFromPickToDropOff,
  //           longDistancePerMile: _fare.longDistancePerMile,
  //         },
  //         valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
  //       };
  //     } else {
  //       rideFare = distanceFromPickToDropOff * _fare.farePerMile;
  //       obj.rideFare = rideFare;
  //       obj.rideFareStructure = {
  //         values: {
  //           distanceFromPickToDropOff: distanceFromPickToDropOff,
  //           farePerMile: _fare.farePerMile,
  //         },
  //         valuesFormula: "distanceFromPickToDropOff * farePerMile",
  //       };
  //     }
  //     // yahan takh
  //   }
  //   const weekday = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   // console.log(company)
  //   for (let key of Object.keys(auth.user.companyId.availability)) {
  //     if (key === weekday[oldDate.getDay()]) {
  //       if (auth?.user?.companyId?.availability[key] === false) {
  //         offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
  //         obj.offHourFee = offHourFee;
  //       }
  //     }
  //   }
  //   // console.log(company.offHours.from.substring(0,2))
  //   // console.log(company.offHours.from.substring(3,5))

  //   if (offHourFee === 0) {
  //     if (
  //       parseInt(auth?.user?.companyId?.offHours?.from?.substring(0, 2)) <=
  //         parseInt(od.toString().split(" ")[4].substring(0, 2)) &&
  //       parseInt(od.toString().split(" ")[4].substring(0, 2)) <=
  //         parseInt(auth?.user?.companyId?.offHours?.to.substring(0, 2))
  //     ) {
  //       if (
  //         parseInt(auth?.user?.companyId?.offHours?.from?.substring(3, 5)) <=
  //         parseInt(od.toString().split(" ")[4].substring(3, 5))
  //       ) {
  //         offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
  //         obj.offHourFee = offHourFee;
  //       }
  //     }
  //     // else {
  //     //   console.log("normal")
  //     // }
  //   }
  //   if (offHourFee === 0) {
  //     amount = (rideFare + baseFare + deadheadFare).toFixed(2);
  //     // yahan se new logic
  //     obj.amount = parseFloat(amount);
  //     obj.amountStructure = {
  //       values: {
  //         rideFare: rideFare,
  //         baseFare: baseFare,
  //         deadheadFare: deadheadFare,
  //       },
  //       valuesFormula: "rideFare + baseFare + deadheadFare",
  //     };
  //     // yahan takh
  //   } else {
  //     // amount = (rideFare + baseFare + offHourFee + deadheadFare).toFixed(2);
  //     amount = (rideFare + baseFare + deadheadFare).toFixed(2);
  //     // yahan se new logic
  //     obj.amount = parseFloat(amount);
  //     obj.amountStructure = {
  //       values: {
  //         rideFare: rideFare,
  //         baseFare: baseFare,
  //         offHourFee: offHourFee,
  //         deadheadFare: deadheadFare,
  //       },
  //       // valuesFormula: 'rideFare + baseFare + offHourFee + deadheadFare',
  //       valuesFormula: "rideFare + baseFare + deadheadFare",
  //     };
  //     // yahan takh
  //   }
  //   // yahan se new logic
  //   let m = 0;
  //   let hr = 0;
  //   let tempHr = 0;
  //   eta.mins.forEach((e) => {
  //     m += e;
  //   });
  //   tempHr = Math.floor(m / 60);
  //   m = m % 60;
  //   eta.hour.forEach((e) => {
  //     hr += e;
  //   });
  //   hr += tempHr;
  //   let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
  //   obj.estimatedTime = estimatedTime;
  //   let newDropOffEta = new Date(
  //     oldDate.toISOString().substring(0, 10) + "T" + oldDateTime + ".000Z"
  //   ).getTime();

  //   // console.log("etaeta,", hr, "hrs", m, "mins ", newDropOffEta);
  //   let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
  //   newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
  //   newDropOffEta = newDropOffEta.toString().split(" ")[4];

  //   let etaTime = newDropOffEta.substring(0, 5);
  //   obj.etaTime = etaTime;
  //   // console.log("etaeta,", hr, 'hrs', m, "mins ", newDropOffEta)
  //   let driverPercentage = auth?.user?.companyId?.driverPercentage;
  //   obj.driverPercentage = driverPercentage;
  //   let driverAmount = parseFloat(
  //     (
  //       (amount - deadheadFare / 2) *
  //       (auth?.user?.companyId?.driverPercentage / 100)
  //     ).toFixed(2)
  //   );
  //   let companyAmount = parseFloat(amount - driverAmount);
  //   obj.driverAmount = driverAmount;
  //   obj.companyAmount = companyAmount;
  //   // console.log("objobjobjobj", obj);

  //   // setFareDetails(obj);
  //   const dataObj = {
  //     companyId: auth?.user?.companyId.id,
  //     customerId: customer.id,
  //     type: type.id,
  //     serviceName: res[5],
  //     // serviceName: res[4],
  //     pickUpAddress: { address: res[7], latitude: pUpLat, longitude: pUpLng },
  //     pickUpDate: oldDate.toISOString().substring(0, 10),
  //     pickUpTime: oldDateTime.substring(0, 5),
  //     dropOffAddress: {
  //       address: res[8],
  //       latitude: dOffLat,
  //       longitude: dOffLng,
  //     },
  //     dropOffDate: new Date(newDate).toISOString().substring(0, 10),
  //     dropOffTime: newDateTime.substring(0, 5),
  //     dateForReservation: oldDate.toISOString().substring(0, 10),
  //     rideHistory: [
  //       {
  //         address: { address: res[7], latitude: pUpLat, longitude: pUpLng },
  //         date: oldDate.toISOString().substring(0, 10),
  //         time: oldDateTime.substring(0, 5),
  //       },
  //       {
  //         address: { address: res[8], latitude: dOffLat, longitude: dOffLng },
  //         date: new Date(newDate).toISOString().substring(0, 10),
  //         time: newDateTime.substring(0, 5),
  //       },
  //     ],
  //     // bookingDate: new Date(Math.round((reservationBody.dataImport[0][16] - 25569) * 86400 * 1000))
  //     //   .toISOString()
  //     //   .substring(0, 10),
  //     bookingDate: moment().format("YYYY-MM-DD"),
  //     fareAndDistance: obj,
  //     distanceFromBaseLocation: distanceFromBaseLocation,
  //     distanceFromPickToDropOff: distanceFromPickToDropOff,
  //     totalMileage: totalMileage,
  //     totalAmount: amount,
  //     // totalAmount: obj.driverAmount,
  //     actions: [
  //       {
  //         userId: auth.user.id,
  //         action: "create",
  //         details: `${auth.user.firstName} ${auth.user.lastName} has created the booking.`,
  //       },
  //     ],
  //     // ...res[10]
  //     // loadNumber: res[10]
  //     ...(res[0] && {
  //       loadNumber: res[0],
  //     }),
  //   };
  //   let oldTempData = [];
  //   oldTempData = tempData;
  //   oldTempData.push(dataObj);
  //   setTempData(oldTempData);
  // };

  const removeItemByIndex = (indexToRemove) => {
    // Create a new array without the element at the specified index
    const newArr = [
      ...tempData.slice(0, indexToRemove),
      ...tempData.slice(indexToRemove + 1),
    ];

    // Update the state with the new array
    setTempData(newArr);
  };
  return (
    <div
      className={`modal fade show`}
      id="uploadDoc"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      style={{ display: "block", paddingRight: "10px" }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Upload File
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleUploadFileModal}
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="file"
                  >
                    Upload Your CSV / XLS File{" "}
                    <sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="file"
                    className="input-group-text  f-14"
                    name="file"
                    id="file"
                    style={{ width: "100%" }}
                    autocomplete="off"
                    onChange={handleSelectFile}
                    // value={file}
                  />
                </div>
                {loading && (
                  <>
                    <ProgressBar
                      now={progress}
                      min={0}
                      max={100}
                      variant="success"
                      animated={true}
                      striped={true}
                    />
                    <br />
                  </>
                )}
              </div>
              {/* <AdminBookingUploadExcelData /> */}
              {/* <div className="col-md-12">
                <div className="text-start">
                  <button
                    type="button"
                    className="btn-primary rounded f-14 p-2 mr-3"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-secondary rounded f-14 p-2 mr-3"
                  >
                    Reset
                  </button>
                </div>
              </div> */}
            </div>
            {(showSubmit === true || showSubmit === null) && (
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table border w-100 dataTable no-footer"
                    id="leads-table"
                    role="grid"
                    aria-describedby="leads-table_info"
                    style={{ overflowX: "auto" }}
                  >
                    <thead className="card-header">
                      <tr role="row">
                        <th
                          title="Load#"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Load#
                        </th>
                        <th
                          title="Date"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Date
                        </th>
                        <th
                          title="Passengers"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Passengers
                        </th>
                        <th
                          title="Service Name"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Service Name{" "}
                        </th>
                        <th
                          title="Time"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Time
                        </th>
                        <th
                          title="Pickup - Drop Off"
                          className="sorting border-right wi-30min"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Pickup - Drop Off{" "}
                        </th>
                        <th
                          title="Remove"
                          className="sorting border-right w-30"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Remove{" "}
                        </th>
                        <th
                          title="Uploaded"
                          className="sorting border-right w-30"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Uploaded{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tempData?.length > 0 &&
                        // reservation.reservation.previous.map((current, i) => {
                        tempData.map((current, i) => {
                          return (
                            <AdminBookingUploadFileModalTableRow
                              current={current}
                              i={i}
                              removeItemByIndex={removeItemByIndex}
                              data={data}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <div className="col-md-12">
              <div className="text-end">
                {showSubmit === true ? (
                  <button
                    type="button"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    onClick={handleFileUploadOpt}
                    // disabled={!file}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    onClick={handleFileUpload}
                    disabled={!file}
                  >
                    Upload
                  </button>
                )}

                {/* <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  onClick={handleClearFile}
                >
                  Reset
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminBookingUploadFileModal;
