/** @format */

import React from "react";

const AdminBookingAuditTrialActivityModal = ({
  showAuditTrialDropDown,
  openAuditTrialDropDown,
  current,
}) => {
  console.log(current, "current 2");

  return (
    <>
      <div
        className={`modal fade ${
          showAuditTrialDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openAuditTrialDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {/* <form onSubmit={handleSumbit}> */}
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Audit Trial
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openAuditTrialDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {current?.actions?.map((item, i) => {
                      const utcDate = new Date(item?.time);
                      const chicagoDate = new Date(
                        utcDate.toLocaleString("en-US", {
                          timeZone: "America/Chicago",
                        })
                      );
                      const chicagoDateTimeString =
                        chicagoDate.toLocaleString("en-US");
                      return (
                        <p key={i}>
                          {item.details} - {chicagoDateTimeString}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openAuditTrialDropDown()}
                >
                  Close
                </button>
                {/* <button
                                    type="submit"
                                    className="btn-primary rounded f-14 p-2 mr-3"
                                >
                                    Submit
                                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminBookingAuditTrialActivityModal;
